<template>
  <routerView />
<!--  <b-container><Task2></Task2>
  </b-container>-->

</template>

<script>
import Vue from 'vue';
import Task from "./comp/Task";
import Task2 from "./comp/Task2";

export default {
  name: "App",
  components:{Task, Task2}
}
</script>