<template>
  <div class="root-page container">
    <div class="row">
      <div class="col">
        <b-spinner
            v-if="isLoading"
            variant="primary"
            label="Spinning"
        ></b-spinner>

        <div v-else>
          <b-table
              id="my-table"
              :items="items"
              :per-page="perPage"
              :current-page="currentPage"
              small
          ></b-table>

         <b-pagination
         v-model="currentPage"
         :total-rows="rows"
         :per-page="perPage"
         aria-controls="my-table"
         ></b-pagination>
        </div>
      </div>
    </div>
    <div class="alert alert-info">{{page}}</div>
  </div>
</template>

<script>
//import TaskService from '@/services/taskService';
//import Paginator from '@/components/paginator/Paginator.vue';
const COUNT_ON_PAGE = 10;
export default {
  data () {
    return {
      isLoading: true,
      perPage: 10,
      currentPage: 2,
      items: [],
      page:" null"

    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.page  = route.path;
      },
      immediate: true
    }
  },
  components: {
    /*Paginator*/
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
 async created () {
const data = await import("../data/data.json");
this.items=data.default;
this.isLoading=false;
  },


  methods: {

  }
}
</script>