<template>
  <div>Not found<div class="alert alert-info"> {{page}}</div>
  </div>

</template>

<script>
import router from "../router";

export default {
  name: "Not",
  watch: {
    $route: {
      handler: function(route) {
        this.page  = route.path;
      },
      immediate: true
    }
  },
  data(){
    page:" null";
  },




}
</script>

<style scoped>

</style>