import Vue from 'vue';

import Not from "../comp/Not";
import VueRouter from 'vue-router';
import Task from "../comp/Task";
import Task2 from "../comp/Task2";



Vue.use(VueRouter);

const routes = [
    {
        path: '/rbase/',
        component:Task2
    },

    {
        path: '/rbase/task',
        component: Task
    },

    {
        path: '/rbase/*',
        component: Not
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
