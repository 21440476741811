<template>
  <div>
    <div class="row">
      <div class="col">
        <b-spinner
            v-if="isLoading"
            variant="primary"
            label="Spinning"
        ></b-spinner>

        <div v-else>
          <b-table
              id="my-table"
              :items="items"
              :per-page="perPage"
              :current-page="currentPage"
              small
          ></b-table>

         <b-pagination
         v-model="currentPage"
         :total-rows="rows"
         :per-page="perPage"
         aria-controls="my-table"
         ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import TaskService from '@/services/taskService';
//import Paginator from '@/components/paginator/Paginator.vue';
const COUNT_ON_PAGE = 10;
export default {
  data () {
    return {
      isLoading: true,
      perPage: 3,
      currentPage: 1,
      items: [],

    }
  },
  components: {
    /*Paginator*/
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  created () {
   setTimeout(()=>{
     this.items=[
       {id: 1, f_n: 'Ivan1', l_n:'Flinstone'},
       {id: 2, f_n: 'Ivan2', l_n:'Flinstone'},
       {id: 3, f_n: 'Ivan3', l_n:'Flinstone'},
       {id: 4, f_n: 'Ivan4', l_n:'Flinstone'},
       {id: 5, f_n: 'Ivan5', l_n:'Flinstone'},
       {id: 6, f_n: 'Ivan6', l_n:'Flinstone'},
       {id: 7, f_n: 'Ivan7', l_n:'Flinstone'},
       {id: 8, f_n: 'Ivan8', l_n:'Flinstone'},
     ];
     this.isLoading=false;
   }, 1000)
  },
  methods: {
    onPageClick (page) {
      let from = page * COUNT_ON_PAGE;
      let to = from + COUNT_ON_PAGE;
      return TaskService.getAll({
        from,
        to
      }).then(tasks => {
        this.items = tasks;
      }).catch(error => {
        console.log('ERROR', error);
      })
    }
  }
}
</script>